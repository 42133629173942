import React from "react";
import GenericPage from "../components/GenericPage";
import GenericPageHeading from "../components/GenericPageHeading";
import GenericPageParagraph from "../components/GenericPageParagraph";


const PrivacyPolicy = () => {
  return (
    <GenericPage title={"Privacy Policy"}>
      <div className={"mt-6 font-medium flex flex-col gap-4"}>
        <GenericPageParagraph>
          <strong>Effective Date:</strong> 02/08/2024
        </GenericPageParagraph>

        <hr className={"mt-4 border-secondary"}/>

        <GenericPageHeading>1. Introduction</GenericPageHeading>
        <GenericPageParagraph>
          Bauvill Group Limited ("we," "our," "us") respects your privacy and is committed to protecting the
          personal information you share with us. This Privacy Policy explains how we collect, use,
          disclose, and safeguard your information when you visit our website and contact us via email or
          phone.
        </GenericPageParagraph>

        <GenericPageHeading>2. Company Information</GenericPageHeading>
        <GenericPageParagraph>
          <ul className={"list-disc ml-16"}>
            <li>
              <strong>Full Company Name:</strong> Bauvill Group Limited
            </li>
            <li>
              <strong>Physical Address:</strong> 51 Riverside Estate, Sir Thomas Longley Road, Rochester, Kent, ME2
              4DP
            </li>
            <li>
              <strong>Contact Email Address:</strong> enquiries@bauvill.co.uk
            </li>
            <li>
              <strong>Contact Phone Number:</strong> 01622 231490
            </li>
          </ul>
        </GenericPageParagraph>

        <GenericPageHeading>3. Data Collection</GenericPageHeading>
        <GenericPageParagraph>
          <strong>Personal Information:</strong> We do not capture user data directly through our website. However, if
          you contact us via email or phone, we may collect the following personal information:

          <ul className={"list-disc ml-16 mb-2"}>
            <li>
              Name
            </li>
            <li>
              Email Address
            </li>
            <li>
              Any other information you provide in your communication
            </li>
          </ul>

          <strong>Non-Personal Information:</strong> We do not collect non-personal information such as browser type
          or IP address.
        </GenericPageParagraph>

        <GenericPageHeading>4. Use of Data</GenericPageHeading>
        <GenericPageParagraph>
          We use the personal information you provide to:
          <ul className={"list-disc ml-16 mb-2"}>
            <li>
              Respond to your inquiries
            </li>
            <li>
              Provide information about our services
            </li>
          </ul>

          We do not use the collected information for marketing purposes. We do not share your personal
          information with third parties.
        </GenericPageParagraph>

        <GenericPageHeading>5. Data Protection</GenericPageHeading>
        <GenericPageParagraph>
          We take appropriate technical and organisational measures to protect your personal
          information against accidental or unlawful destruction, loss, alteration, unauthorised
          disclosure or access. These measures include encryption and secure servers.

          <br/>
          <br/>

          <strong>Data Retention:</strong> We retain personal information for the standard retention period as mandated
          by UK law.

          <br/>
          <br/>

          <strong>Data Deletion:</strong> You have the right to request the deletion of your personal information.
          Please contact us via email or phone to make such a request.
        </GenericPageParagraph>

        <GenericPageHeading>6. User Rights</GenericPageHeading>
        <GenericPageParagraph>
          You have the right to access, correct, or delete your personal information. To exercise these
          rights, please contact us at:

          <ul className={"list-disc ml-16"}>
            <li>
              <strong>Email:</strong> enquiries@bauvill.co.uk
            </li>
            <li>
              <strong>Phone:</strong> 01622 231490
            </li>
          </ul>

          You also have the right to opt-out of data collection and marketing communications as per UK
          law.
        </GenericPageParagraph>

        <GenericPageHeading>7. Cookies and Tracking</GenericPageHeading>
        <GenericPageParagraph>
          Our website uses cookies and other tracking technologies. We use analytics cookies to
          understand how visitors interact with our website.

          <br/>
          <br/>

          <strong>Managing Cookie Preferences:</strong> Users can manage their cookie preferences in accordance with
          UK law. Please refer to our Cookies Policy for more details.
        </GenericPageParagraph>

        <GenericPageHeading>8. Third-Party Links</GenericPageHeading>
        <GenericPageParagraph>
          Our website may contain links to third-party websites, such as clients’ websites within case
          studies. We do not control and are not responsible for the privacy practices of these third-party
          websites. We encourage you to review their privacy policies before providing any personal
          information.
        </GenericPageParagraph>

        <GenericPageHeading>9. Changes to the Privacy Policy</GenericPageHeading>
        <GenericPageParagraph>
          We may update this Privacy Policy from time to time. Any changes will be posted on this page. If
          the changes are significant, we will provide a more prominent notice (e.g., email notification of
          changes to our policy).
        </GenericPageParagraph>

        <GenericPageHeading>10. Compliance</GenericPageHeading>
        <GenericPageParagraph>
          We comply with the General Data Protection Regulation (GDPR) and other applicable privacy
          laws.
        </GenericPageParagraph>

        <GenericPageHeading>11. Miscellaneous</GenericPageHeading>
        <GenericPageParagraph>
          <strong>Children's Privacy:</strong> We do not knowingly collect information from children under the age of 16.

          <br/><br/>

          <strong>International Data Transfers:</strong> We do not transfer data internationally.
        </GenericPageParagraph>

        <hr className={"mt-4 border-secondary"}/>

        <GenericPageParagraph>
          If you have any questions or concerns about this Privacy Policy, please contact us at:

          <ul className={"list-disc ml-16"}>
            <li>
              <strong>Email:</strong> enquiries@bauvill.co.uk
            </li>
            <li>
              <strong>Phone:</strong> 01622 231490
            </li>
          </ul>
        </GenericPageParagraph>

        <hr className={"mt-4 border-secondary"}/>

        <GenericPageParagraph>
          This Privacy Policy was last updated on 02/08/2024.
        </GenericPageParagraph>
      </div>
    </GenericPage>
  )
}

export default PrivacyPolicy;